import * as React from "react"
import tw from "twin.macro"
import MobileBG from "../../images/mobile-background.jpg"
import Form from "./Form"

const Mobile: React.FC<{data: any}>= ({data}) => {
  return (
    <div css={[tw`hidden lg:flex`]}>
      <section
        css={[tw`bg-cover h-32`, `background-image: url(${MobileBG});`]}
      ></section>
      <div css={[tw`flex-col items-center px-3 pb-8 bg-red-300 `]}>

        <Form data={data}/>
      </div>
    </div>
  )
}

export default Mobile
