import { graphql, PageProps } from "gatsby"
import Seo from "../components/SEO"
import SignUp from "../components/SignUp"

const SignUpPage: React.FC<PageProps<any, any, { email: string }>> = ({
  data,
  location,
}) => {
  const formData = data?.allFormData.nodes[0]
  const ageOptions = data?.allAgeOptions.nodes[0]
  const countryOptions = data?.allCountryOptions.nodes[0]

  return (
    <div>
      <Seo title="Sign Up" />

      <SignUp.Primary
        data={{
          formData,
          ageOptions,
          countryOptions,
          email: location?.state?.email,
        }}
      />
      <SignUp.Mobile
        data={{
          formData,
          ageOptions,
          countryOptions,
          email: location?.state?.email,
        }}
      />
    </div>
  )
}

export default SignUpPage

export const query = graphql`
  {
    allFormData {
      nodes {
        email: _0 {
          field
          label
          property
          required
          visitorID
        }
        firstName: _1 {
          field
          label
          property
          required
          visitorID
        }
        custom: _10 {
          field
          label
          property
          required
          visitorID
        }
        lastName: _2 {
          field
          label
          property
          required
          visitorID
        }
        phone: _3 {
          field
          visitorID
          required
          label
          property
          regEx
        }
        age: _4 {
          field
          label
          property
          required
          visitorID
        }
        country: _5 {
          field
          label
          property
          required
          visitorID
        }
        address: _6 {
          field
          property
          label
          required
          visitorID
        }
        city: _7 {
          field
          label
          property
          required
          visitorID
        }
        state: _8 {
          field
          label
          property
          required
          visitorID
        }
        postalCode: _9 {
          field
          label
          property
          required
          visitorID
        }
      }
    }
    allAgeOptions {
      nodes {
        label2: _1 {
          labelStandard
        }
        label3: _2 {
          labelStandard
        }
        label4: _3 {
          labelStandard
        }
        label5: _4 {
          labelStandard
        }
      }
    }
    allCountryOptions {
      nodes {
        _0 {
        label
      }
      _1 {
        label
      }
      # _10 {
      #   label
      # }
      # _11 {
      #   label
      # }
      # _12 {
      #   label
      # }
      # _13 {
      #   label
      # }
      # _15 {
      #   label
      # }
      # _14 {
      #   label
      # }
      # _16 {
      #   label
      # }
      # _17 {
      #   label
      # }
      # _18 {
      #   label
      # }
      # _19 {
      #   label
      # }
      # _2 {
      #   label
      # }
      # _20 {
      #   label
      # }
      # _21 {
      #   label
      # }
      # _22 {
      #   label
      # }
      # _23 {
      #   label
      # }
      # _24 {
      #   label
      # }
      # _26 {
      #   label
      # }
      # _25 {
      #   label
      # }
      # _27 {
      #   label
      # }
      # _28 {
      #   label
      # }
      # _29 {
      #   label
      # }
      # _3 {
      #   label
      # }
      # _30 {
      #   label
      # }
      # _31 {
      #   label
      # }
      # _32 {
      #   label
      # }
      # _33 {
      #   label
      # }
      # _34 {
      #   label
      # }
      # _35 {
      #   label
      # }
      # _36 {
      #   label
      # }
      # _38 {
      #   label
      # }
      # _37 {
      #   label
      # }
      # _39 {
      #   label
      # }
      # _4 {
      #   label
      # }
      # _40 {
      #   label
      # }
      # _41 {
      #   label
      # }
      # _42 {
      #   label
      # }
      # _43 {
      #   label
      # }
      # _5 {
      #   label
      # }
      # _6 {
      #   label
      # }
      # _7 {
      #   label
      # }
      # _8 {
      #   label
      # }
      # _9 {
      #   label
      # }
      }
    }
  }
`
