import * as React from "react"
import tw from "twin.macro"
import Form from "./Form"
import Modal from "../Modal/Registration"

const Primary: React.FC<{data: any}> = ({data}) => {
  return (
    <Modal blue={true} open={true} modalCss={[tw`w-screen lg:max-w-7xl`]}>
      <div css={[tw`lg:mx-auto lg:px-32 py-10 bg-red-300`]}>
        <Form data={data} />
      </div>
    </Modal>
  )
}

export default Primary
 